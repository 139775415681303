.loader-container {
  z-index: 999999;
  background-color: #0f0f0fa2;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  inset: 0;
}

.loader {
  --d: 22px;
  color: #fff;
  box-shadow: calc(1 * var(--d)) calc(0 * var(--d)) 0 0, calc(.707 * var(--d)) calc(.707 * var(--d)) 0 1px, calc(0 * var(--d)) calc(1 * var(--d)) 0 2px, calc(-.707 * var(--d)) calc(.707 * var(--d)) 0 3px, calc(-1 * var(--d)) calc(0 * var(--d)) 0 4px;
  border-radius: 100%;
  width: 3px;
  height: 3px;
  animation: 1s steps(8, end) infinite l27;
}

@keyframes l27 {
  100% {
    transform: rotate(1turn);
  }
}
/*# sourceMappingURL=index.d24a9a42.css.map */
